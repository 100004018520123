import { isDefined } from '@sgme/fp';
import { LogBuilder, type Logger } from '@sgme/logger';
import { front as baseConfig } from 'configuration/loggers/front';

type BaseConfig = typeof baseConfig;

export interface IFrontLogger {
  error(message: string, ...parameters: unknown[]): void;
  warn(message: string, ...parameters: unknown[]): void;
  info(message: string, ...parameters: unknown[]): void;
}

const isTest = typeof process === 'object' && process.env.NODE_ENV === 'test';

class FrontLogger implements IFrontLogger {
  private logger: Logger;

  constructor(config: BaseConfig) {
    const logBuilder = LogBuilder();
    if (!isTest && isDefined(window.sgmeConfiguration)) {
      logBuilder
        .enableServerLogs({
          url: window.sgmeConfiguration.spBridgeHost + config.endpoint,
          prefix: 'SP ',
          storeName: 'SP',
        })
        .setAuthTokenFactory(() => window.sgwtConnect.getAuthorizationHeader() ?? undefined)
        .setApplicationHeader(() => 'SP');
    }
    if (window.sgmeConfiguration?.EnvName === 'dev') {
      logBuilder.enableConsole();
    }
    this.logger = logBuilder.build();
  }

  public startLogSession() {
    this.logger.resumeServerLogs();
  }

  public error(message: string, ...parameters: unknown[]) {
    this.logger.logError(message, ...parameters);
  }

  public warn(message: string, ...parameters: unknown[]) {
    this.logger.logWarning(message, ...parameters);
  }

  public info(message: string, ...parameters: unknown[]) {
    this.logger.logInformation(message, ...parameters);
  }
}

const frontLogger = new FrontLogger(baseConfig);

export const getFrontLogger = (): FrontLogger => {
  return frontLogger;
};
