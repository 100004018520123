import type { SgBootstrapTheme } from '@sgwt/sgwt-widgets-react';

const defaultVariation = import.meta.env.VITE_SGBS_THEME;

function generateSgbsUrls(variation = defaultVariation) {
  const cdnUrl = getCdnUrl();
  return {
    sgbs: `${cdnUrl}/core/css/sg-bootstrap-${variation}.min.css`,
    aggrid: `${cdnUrl}/ag-grid-theme/31-${variation}.min.css`,
    shepherd: `${cdnUrl}/shepherd/theme-${variation}.min.css`,
  };
}

function getCdnUrl() {
  const cdn = import.meta.env.VITE_SGBS_CDN;
  const version = import.meta.env.VITE_SGBS_VERSION;
  return `${cdn}/${version}`;
}

function updateThemeUrls(variation: SgBootstrapTheme) {
  document.body.classList.remove(variation === 'dark' ? 'light-theme' : 'dark-theme');
  document.body.classList.add(variation === 'dark' ? 'dark-theme' : 'light-theme');

  Object.entries(generateSgbsUrls(variation)).forEach(([id, url]) => {
    appendUniqueLinkToDocument(id, url);
  });
}

export function initSgBootstrap(variation = defaultVariation) {
  updateThemeUrls(variation);
  appendUniqueLinkToDocument('icons', `${getCdnUrl()}/icons/index.min.css`);
}

export function updateTheme(variation: SgBootstrapTheme) {
  updateThemeUrls(variation);
}

function appendUniqueLinkToDocument(id: string, url: string) {
  const linkElement = document.querySelector<HTMLLinkElement>(`link[id="${id}"]`);
  if (linkElement) {
    linkElement.href = url;
  } else {
    const newLinkElement = document.createElement('link');
    newLinkElement.rel = 'stylesheet';
    newLinkElement.id = id;
    newLinkElement.href = url;
    document.head.appendChild(newLinkElement);
  }
}
