import { unminifyError } from '@sgme/stacktrace';
import { getFrontLogger } from 'utils/loggers/FrontLogger';

/**
 * Log an error from the front to the back, unminifying the error in the process
 * @param messageTemplate
 * @param error
 */
export async function logFrontError(messageTemplate: string, error: unknown) {
  const err = await unminifyError(error);
  getFrontLogger().error(messageTemplate, err);
}
