import { getFrontLogger } from './loggers/FrontLogger';

export function redirectToRequestAccessPage(httpStatusCode: number, correlationId: string) {
  getFrontLogger().info(
    'Redirecting to REQUEST_ACCESS_PAGE : received {HttpStatusCode} on request with correlationId {CorrelationId}',
    httpStatusCode,
    correlationId,
  );
  const unauthorizedUrl: string = window.sgmeConfiguration.unauthorizedUrl;
  const alreadyContainsARedirectUrl = !unauthorizedUrl.endsWith('redirectUri=');
  const errorPageUrl = unauthorizedUrl + (alreadyContainsARedirectUrl ? '' : location.href);
  window.location.replace(errorPageUrl);
}
export function extractFakeSsoFromQueryString(queryString: string): string | null {
  try {
    const lowerCaseQueryString = queryString.toLowerCase();
    if (!lowerCaseQueryString.includes('fakesso=')) {
      return null;
    }

    const fakeSsoQueryString = lowerCaseQueryString.split('fakesso=').pop();
    if (!fakeSsoQueryString) {
      return null;
    }

    if (!fakeSsoQueryString.includes('@sgcib.com')) {
      return null;
    }

    const user = fakeSsoQueryString.split('@sgcib.com').shift();

    if (!user) {
      return null;
    }

    return `${user}@sgcib.com`;
  } catch (error) {
    return null;
  }
}
