import { setupSGWTConnect } from '@sgwt/connect-core';
import type { ISGWTConnectIdTokenClaims, SGWTConnectCore } from '@sgwt/connect-core';

const mockedSgConnect = {
  getAuthorizationHeader: () => 'mocked-bearer-token',
  isAuthorized: () => true,
  on: () => mockedSgConnect,
  requestAuthorization: () => {
    return mockedSgConnect;
  },
  getIdTokenClaims: () => {
    return { sub: 'fake.user@sgcib.com' } as ISGWTConnectIdTokenClaims;
  },
} as Partial<SGWTConnectCore> as SGWTConnectCore;

function bootstrapSgConnect(sgmeConfiguration: SgmeConfiguration): SGWTConnectCore {
  if (!sgmeConfiguration) {
    throw new Error('sgmeConfiguration is not provided on Window');
  }
  if (sgmeConfiguration.disableSgConnect === true) {
    return mockedSgConnect; // for integration test only
  }
  return setupSGWTConnect(sgmeConfiguration.sgConnect);
}

export const sgwtConnect = bootstrapSgConnect(window.sgmeConfiguration);
